"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Photo4 = exports.Photo3 = exports.Photo2 = exports.Photo1 = void 0;
//exports.Photo1 = require('./Images/PhotoPageImages/Vanya6Image.jpg');
//exports.Photo2 = require('./Images/PhotoPageImages/Vanya5Image.jpg');
exports.Photo1 = require('./Images/PhotoPageImages/Vanya4Image.jpg');
exports.Photo2 = require('./Images/PhotoPageImages/Vanya3Image.jpg');
exports.Photo3 = require('./Images/PhotoPageImages/Vanya2Image.jpg');
exports.Photo4 = require('./Images/PhotoPageImages/Vanya1Image.jpg');


